import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      sn: ''
    };
  },
  methods: {
    onSubmit() {
      if (this.sn) {
        this.$router.push(`/sn/${this.sn}`);
      } else {
        // 添加错误提示
      }
    },
    onBackClick() {
      // 处理返回操作
    }
  }
};