import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2f138e90"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 2,
  class: "alert"
};
const _hoisted_2 = {
  class: "fixed-nav-bar"
};
const _hoisted_3 = {
  class: ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_loading = _resolveComponent("van-loading");
  const _component_van_watermark = _resolveComponent("van-watermark");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_divider = _resolveComponent("van-divider");
  const _component_van_cell = _resolveComponent("van-cell");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  return _openBlock(), _createElementBlock(_Fragment, null, [$data.isLoading ? (_openBlock(), _createBlock(_component_van_loading, {
    key: 0,
    class: "loading",
    color: "#1989fa",
    type: "spinner"
  })) : _createCommentVNode("", true), !$data.isLoading ? (_openBlock(), _createBlock(_component_van_watermark, {
    key: 1,
    "gap-x": 70,
    "gap-y": 170,
    width: 200,
    content: "出厂检验合格"
  })) : _createCommentVNode("", true), $data.alert_visible ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", null, _toDisplayString($data.alert_message), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_nav_bar, {
    "left-arrow": "",
    "left-text": "返回",
    placeholder: "",
    title: "传感器详细信息",
    onClickLeft: $options.onClickLeft
  }, null, 8, ["onClickLeft"])]), _createVNode(_component_van_divider), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_cell_group, {
    title: "基本信息"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_cell, {
      value: $data.sensorDetails.snNo || '加载中...',
      title: "产品序列号SN"
    }, null, 8, ["value"]), _createVNode(_component_van_cell, {
      value: $data.sensorDetails.coreModel || '加载中...',
      title: "芯体型号Type"
    }, null, 8, ["value"]), _createVNode(_component_van_cell, {
      value: $data.sensorDetails.manufacturerChinese || '加载中...',
      title: "制造商"
    }, null, 8, ["value"])]),
    _: 1
  }), _createVNode(_component_van_cell_group, {
    title: "产品性能参数"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_cell, {
      value: $data.sensorDetails.range || '加载中...',
      title: "量程Range"
    }, null, 8, ["value"]), _createVNode(_component_van_cell, {
      value: $data.sensorDetails.incentive || '加载中...',
      title: "激励Supply"
    }, null, 8, ["value"]), _createVNode(_component_van_cell, {
      value: $data.sensorDetails.zeroPoint || '加载中...',
      title: "零点Zero"
    }, null, 8, ["value"]), _createVNode(_component_van_cell, {
      value: $data.sensorDetails.fullScale || '加载中...',
      title: "满量程Span"
    }, null, 8, ["value"]), _createVNode(_component_van_cell, {
      value: $data.sensorDetails.nonlinear || '加载中...',
      title: "非线性NL"
    }, null, 8, ["value"])]),
    _: 1
  }), _createVNode(_component_van_cell_group, {
    title: "电气连接Connection"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_cell, {
      value: $data.sensorDetails.wiringMethod || '加载中...',
      title: "连接方式"
    }, null, 8, ["value"]), _createVNode(_component_van_cell, {
      value: $data.sensorDetails.wiringDescriptionOne || '加载中...',
      title: "连接方式参数1"
    }, null, 8, ["value"]), _createVNode(_component_van_cell, {
      value: $data.sensorDetails.wiringDescriptionTwo || '加载中...',
      title: "连接方式描述2"
    }, null, 8, ["value"]), _createVNode(_component_van_cell, {
      value: $data.sensorDetails.wiringDescriptionThree || '加载中...',
      title: "连接方式描述3"
    }, null, 8, ["value"])]),
    _: 1
  })])], 64);
}