import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'index'
    }
  },

  {
    name: 'sn',
    path: '/sn/:sn',
    component: () => import('./view/sn'),
    meta: {
      title: 'SN详情'
    }
  },
  {
    name: 'index',
    path: '/index',
    component: () => import('./view/index'),
    meta: {
      title: '传感器查询'
    }
  }
];

const router = createRouter({
  routes,
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
