import "core-js/modules/es.array.push.js";
import { useRoute, useRouter } from 'vue-router';
export default {
  data() {
    return {
      // 回传template数据
      sensorDetails: {},
      //加载状态提示标
      isLoading: true,
      //json数据判断
      hasData: false,
      //报警提示标
      alert_visible: false,
      alert_message: ''
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    return {
      route,
      router
    };
  },
  mounted() {
    this.fetchSensorDetails();
  },
  methods: {
    onClickLeft() {
      this.$router.push('/index');
    },
    async fetchSensorDetails() {
      const sn = this.route.params.sn;
      if (!sn) {
        this.alert_message = '无SN参数';
        this.alert_visible = true;
        setTimeout(() => this.router.push('/'), 3000);
        return;
      }
      this.isLoading = true;
      try {
        const response = await fetch(`https://query.aswt-tech.com/api/filedetail?snNo=${sn}`);
        const data = await response.json();
        if (data.code === 500) {
          this.alert_message = '接口请求过快，稍后再试';
          this.alert_visible = true;
          setTimeout(() => this.router.push('/index'), 5000);
          return;
        }
        if (!data.data.resultList || data.data.resultList.length === 0) {
          this.alert_message = '该SN记录不存在';
          this.alert_visible = true;
          setTimeout(() => this.router.push('/index'), 5000);
          return;
        }
        //遍历json第一项，容未定义的字段值替换为“此项暂无”
        this.sensorDetails = this.processData(data.data.resultList[0]);
        this.hasData = true;
      } catch (error) {
        // console.error('API 请求失败:', error);
        this.alert_message = '请求失败';
        setTimeout(() => this.router.push('/index'), 1000);
      } finally {
        this.isLoading = false;
      }
    },
    processData(data) {
      let processedData = {};
      for (let key in data) {
        processedData[key] = data[key] || '此项暂无';
      }
      return processedData;
    }
  }
};