import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import { Toast } from 'vant';
import { Watermark } from 'vant';
import { Cell, CellGroup } from 'vant';
import { NavBar } from 'vant';
import { Loading } from 'vant';
import { Notify } from 'vant';

const app = createApp(App);
app.use(router);
app.mount('#app');
app.use(Toast);
app.use(Watermark);
app.use(Cell);
app.use(CellGroup);
app.use(NavBar);
app.use(Loading);
app.use(Notify);