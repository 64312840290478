import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache) {
  const _component_van_watermark = _resolveComponent("van-watermark");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_watermark, {
    "gap-x": 300,
    "gap-y": 100,
    rotate: -72,
    width: 160,
    content: "沃天传感器",
    opacity: "0.2"
  }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view)])], 64);
}